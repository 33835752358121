define("discourse/plugins/docuss/discourse/initializers/docuss", ["exports", "@ember/object", "@ember/runloop", "discourse/controllers/composer", "discourse/lib/plugin-api", "discourse/lib/utilities", "discourse/models/composer", "discourse/plugins/docuss/discourse/lib/DcsIFrame", "discourse/plugins/docuss/discourse/lib/DcsTag", "discourse/plugins/docuss/discourse/lib/discourseAPI", "discourse/plugins/docuss/discourse/lib/onAfterRender", "discourse/plugins/docuss/discourse/lib/onDidTransition"], function (_exports, _object, _runloop, _composer, _pluginApi, _utilities, _composer2, _DcsIFrame, _DcsTag, _discourseAPI, _onAfterRender, _onDidTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "docuss",
    initialize(container, app) {
      // If plugin is disabled, quit
      if (!container.lookup("service:site-settings").docuss_enabled) {
        return;
      }
      let dcsIFrame;

      // Initialize plugin
      const initializePlugin = () => {
        (0, _utilities.setDefaultHomepage)("docuss");

        // Create the IFrame instance
        dcsIFrame = new _DcsIFrame.DcsIFrame(app, container);

        // Wait until page is rendered, then modify stuff
        afterRender().then(() => (0, _onAfterRender.onAfterRender)(container));

        // Add the 'r' query param
        container.lookup("controller:application").reopen({
          queryParams: {
            showRight: "r"
          },
          showRight: true
        });

        // Hide the header-sidebar-toggle element for now, because it doesn't work on docuss.
        const sidebarToggle = document.querySelector('.header-sidebar-toggle');
        if (sidebarToggle) {
          sidebarToggle.style.display = 'none';
        }
        container.dcsHeaderLogo = {
          _logoUrl: null,
          _mobileLogoUrl: null,
          _smallLogoUrl: null,
          _href: null,
          setLogo(logos) {
            // Store new values
            container.dcsHeaderLogo._logoUrl = logos?.logoUrl;
            container.dcsHeaderLogo._mobileLogoUrl = logos?.mobileLogoUrl;
            container.dcsHeaderLogo._smallLogoUrl = logos?.smallLogoUrl;
            container.dcsHeaderLogo._href = logos?.href;

            // Force header rerender using modern Discourse API
            (0, _pluginApi.withPluginApi)("0.8.30", api => {
              api.modifyClass("component:site-header", {
                pluginId: "docuss",
                logoUrl() {
                  return container.dcsHeaderLogo._logoUrl || this._super();
                },
                mobileLogoUrl() {
                  return container.dcsHeaderLogo._mobileLogoUrl || this._super();
                },
                smallLogoUrl() {
                  return container.dcsHeaderLogo._smallLogoUrl || this._super();
                },
                href() {
                  return container.dcsHeaderLogo._href || this._super();
                }
              });

              // Force a header refresh
              const header = document.querySelector(".d-header");
              if (header) {
                const headerComponent = header.querySelector(".title");
                if (headerComponent) {
                  headerComponent.style.display = "none";
                  setTimeout(() => {
                    headerComponent.style.display = "";
                  }, 0);
                }
              }
            });
          }
        };
        let lastUrl = "";
        let shrinkComposer = true;
        (0, _pluginApi.withPluginApi)("0.8.30", api => {
          // Page changed event
          api.onAppEvent("page:changed", _ref => {
            let {
              currentRouteName,
              title,
              url
            } = _ref;
            if (url === lastUrl) return;
            const queryParamsOnly = url.split("?")[0] === lastUrl.split("?")[0];
            lastUrl = url;
            (0, _onDidTransition.onDidTransition)({
              container,
              iframe: dcsIFrame,
              routeName: currentRouteName,
              queryParamsOnly
            });
            if (shrinkComposer) {
              container.lookup("controller:composer")?.shrink();
            }
            shrinkComposer = true;
          });
        });

        // Modify iframe creation to use proxy if needed
        const iframeContainer = document.querySelector(".dcs-iframe-container");
        if (iframeContainer) {
          const iframe = iframeContainer.querySelector("iframe");
          if (iframe) {
            const originalUrl = iframe.src;
            const proxyUrl = `/proxy?url=${encodeURIComponent(originalUrl)}`;

            // Create a new iframe with sandbox attributes
            const newIframe = document.createElement('iframe');
            newIframe.src = proxyUrl;
            newIframe.setAttribute('sandbox', 'allow-same-origin allow-scripts allow-popups allow-forms allow-presentation');
            newIframe.setAttribute('allow', 'fullscreen');
            newIframe.style.width = '100%';
            newIframe.style.height = '100%';
            newIframe.style.border = 'none';

            // Add event listener for load errors
            newIframe.onerror = () => {
              console.error('Failed to load iframe content');
            };

            // Replace the old iframe
            iframe.parentNode.replaceChild(newIframe, iframe);
          }
        }

        // Rest of initialization...
        _composer.default.reopen({
          composeStateChanged: (0, _object.observer)("model.composeState", function () {
            const state = this.get("model.composeState");
            if (state !== _composer2.default.OPEN) return;
            const model = this.get("model");
            const tags = model.tags || model.topic && model.topic.tags;
            const dcsTag = tags && tags.find(t => _DcsTag.DcsTag.parse(t));
            if (!dcsTag) return;
            let path;
            const topic = model.topic;
            if (topic) {
              path = `/t/${topic.slug}/${topic.id}?r=true`;
            } else {
              const isCommentMode = tags.includes("dcs-comment");
              const modeTag = isCommentMode ? "dcs-comment" : "dcs-discuss";
              path = `/tags/intersection/${modeTag}/${dcsTag}?r=true`;
            }
            shrinkComposer = false;
            container.lookup("service:router").transitionTo(path);
          }),
          tagsChanged: (0, _object.observer)("model.tags", function () {
            const model = this.get("model");
            const tags = model?.tags;
            const dcsTag = tags?.find(tag => _DcsTag.DcsTag.parse(tag));
            if (!dcsTag) return;
            const isCommentMode = tags.includes("dcs-comment");
            if (isCommentMode) {
              model.setProperties({
                title: _discourseAPI.discourseAPI.commentTopicTitle(dcsTag)
              });

              // Update composer button text
              (0, _runloop.schedule)("afterRender", () => {
                $("#reply-control .save-or-cancel .d-button-label").text("Add Comment");
              });
            }
          })
        });
      };

      // Just initialize without trying to create tags
      initializePlugin();
    }
  };
  const afterRender = res => new Promise(resolve => {
    (0, _runloop.schedule)("afterRender", null, () => resolve(res));
  });
});